import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import lime from '@material-ui/core/colors/lime';

let pal = lime;

let theme = createMuiTheme({
    palette: {
      primary: pal,
    },
});
theme = responsiveFontSizes(theme);
export default theme;