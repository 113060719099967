import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './appconfig/theme';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'development') {
  Sentry.init({
    dsn: "https://95ab88493423483b8c1ab67b96a048bc@o374202.ingest.sentry.io/5251324",
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
  });
}

ReactDOM.render(
  <React.StrictMode>
     <ThemeProvider theme={theme}><App /></ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
